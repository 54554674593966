import React, { Component } from "react";

export default class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 100,
      selectedInner: "Yes",
      selectedOuterMedia: "Film",
      selectedPrinting: "White Box",
      selectedMasterCartonDetails: "5 Ply 20 pcs",
      selectedRotatingAssembly: "No",

      molding: 15.0,
      inner: 100.67,
      outerPaper: 0.0,
      outerFilm: 170.0,
      duplexBox: 4.0,
      masterCartoon: 2.09,
      pakingMat: 1.0,
      labour: 3.0,
      otherMaterial: 1.5,
      designningCharges: 30.0,
      basicTotal: 326.59,
      addOverheads: 366.59,
      rotatingAssembly: 0.0,
      dealerPrice: 440,
      customerPrice: 532,
      mastCartoonList: [
        {
          label: "5 Ply 5 pcs",
          boxCost: 3.33,
          labour: 1.5,
        },
        {
          label: "5 Ply 10 pcs",
          boxCost: 2.54,
          labour: 1.25,
        },
        {
          label: "5 Ply 20 pcs",
          boxCost: 2.09,
          labour: 1.0,
        },
        {
          label: "5 Ply 30 pcs",
          boxCost: 1.55,
          labour: 0.8,
        },
        {
          label: "5 Ply 50 pcs",
          boxCost: 1.31,
          labour: 0.75,
        },
        {
          label: "5 Ply 100 pcs",
          boxCost: 1.12,
          labour: 0.5,
        },
        {
          label: "5 Ply 200 pcs",
          boxCost: 0.79,
          labour: 0.35,
        },
        {
          label: "7 Ply 5 pcs",
          boxCost: 4.55,
          labour: 2.0,
        },
        {
          label: "7 Ply 10 pcs",
          boxCost: 3.47,
          labour: 1.75,
        },
        {
          label: "7 Ply 20 pcs",
          boxCost: 2.85,
          labour: 1.5,
        },
        {
          label: "7 Ply 30 pcs",
          boxCost: 2.12,
          labour: 1.3,
        },
        {
          label: "7 Ply 50 pcs",
          boxCost: 1.79,
          labour: 1.25,
        },
        {
          label: "7 Ply 100 pcs",
          boxCost: 1.53,
          labour: 1.0,
        },
        {
          label: "7 Ply 200 pcs",
          boxCost: 1.08,
          labour: 0.85,
        },
      ],
    };
  }
  componentDidMount() {
    this.setState(
      {
        outerPaper: this.state.outerPaper.toFixed(2),
        duplexBox: this.state.duplexBox.toFixed(2),
        pakingMat: this.state.pakingMat.toFixed(2),
        labour: this.state.labour.toFixed(2),
        rotatingAssembly: this.state.rotatingAssembly.toFixed(2),
        dealerPrice: this.state.dealerPrice.toFixed(2),
        customerPrice: this.state.customerPrice.toFixed(2),
        otherMaterial: this.state.otherMaterial.toFixed(2),
        designningCharges: this.state.designningCharges.toFixed(2),
      },
      () => this.calculateTotal()
    );
  }
  handleCalculateByQty = () => {
    if (this.state.quantity < 1000) {
      this.setState({ molding: 15.0 }, () => {
        if (this.state.selectedInner) {
          this.handleCalculateByInner();
        }
        if (this.state.selectedOuterMedia) {
          this.handleCalculateOuterMedia();
        }
        if (this.state.selectedPrinting) {
          this.handleCalculatePrinting();
        }
      });
    } else {
      this.setState({ molding: 10.0 }, () => {
        if (this.state.selectedInner) {
          this.handleCalculateByInner();
        }
        if (this.state.selectedOuterMedia) {
          this.handleCalculateOuterMedia();
        }
        if (this.state.selectedPrinting) {
          this.handleCalculatePrinting();
        }
      });
    }
    let designningCharges = parseFloat(3000 / this.state.quantity);
    this.setState({
      designningCharges: designningCharges.toFixed(2),
    });
  };
  handleCalculateByInner = () => {
    if (this.state.selectedInner == "Yes") {
      let cal = (10000 / Number(this.state.quantity)).toFixed(2);
      this.setState({ inner: cal, labour: "3.00", otherMaterial: "1.50" }, () =>
        this.calculateTotal()
      );
    } else {
      this.setState(
        {
          inner: 0.0,
          labour: "2.00",
          otherMaterial: "1.00",
        },
        () => this.calculateTotal()
      );
    }
  };
  handleCalculateOuterMedia = () => {
    if (this.state.selectedOuterMedia == "Paper") {
      let cal = 8000 / this.state.quantity;
      this.setState({ outerPaper: cal.toFixed(2) }, () =>
        this.calculateTotal()
      );
    } else {
      let cal = 0;
      this.setState({ outerPaper: cal.toFixed(2) }, () =>
        this.calculateTotal()
      );
    }

    if (this.state.selectedOuterMedia == "Film") {
      let cal = 17000 / this.state.quantity;
      this.setState({ outerFilm: cal.toFixed(2) }, () => this.calculateTotal());
    } else {
      let cal = 0;
      this.setState({ outerFilm: cal.toFixed(2) }, () => this.calculateTotal());
    }
  };
  handleCalculatePrinting = () => {
    if (this.state.selectedPrinting == "Without Box") {
      let cal = 0;
      this.setState({ duplexBox: cal.toFixed(2) }, () => this.calculateTotal());
    } else if (this.state.selectedPrinting == "White Box") {
      let cal = 4;
      this.setState({ duplexBox: cal.toFixed(2) }, () => this.calculateTotal());
    } else {
      let cal = 15000 / this.state.quantity;
      this.setState({ duplexBox: cal.toFixed(2) }, () => this.calculateTotal());
    }
  };
  handleCalculateMasterCartoonMedia = () => {
    let list = this.state.mastCartoonList;
    for (let item of list) {
      if (item.label == this.state.selectedMasterCartonDetails) {
        this.setState(
          {
            masterCartoon: item.boxCost.toFixed(2),
            pakingMat: item.labour.toFixed(2),
          },
          () => this.calculateTotal()
        );
        return;
      }
    }
  };
  calculateTotal = () => {
    let rAs = this.state.selectedRotatingAssembly == "Yes" ? 5 : 0;
    this.setState({
      rotatingAssembly: rAs.toFixed(2),
    });
    // console.log("this.state", this.state);
    let molding = parseFloat(this.state.molding);
    let inner = parseFloat(this.state.inner);
    let outerPaper = parseFloat(this.state.outerPaper);
    let outerFilm = parseFloat(this.state.outerFilm);
    let duplexBox = parseFloat(this.state.duplexBox);
    let masterCartoon = parseFloat(this.state.masterCartoon);
    let pakingMat = parseFloat(this.state.pakingMat);
    let labour = parseFloat(this.state.labour);
    let otherMaterial = parseFloat(this.state.otherMaterial);
    let designningCharges = parseFloat(this.state.designningCharges);
    // BASIC TOTAL
    let basicTotal =
      parseFloat(molding) +
      parseFloat(inner) +
      parseFloat(outerPaper) +
      parseFloat(outerFilm) +
      parseFloat(duplexBox) +
      parseFloat(masterCartoon) +
      parseFloat(pakingMat) +
      parseFloat(labour) +
      parseFloat(otherMaterial) +
      parseFloat(designningCharges);
    this.setState({ basicTotal });

    // ADD OVERHEADS
    let one = parseFloat(4000 / this.state.quantity);
    let two = parseFloat(basicTotal * 0.03);
    let max = Math.max(one, two);
    let addOverheads = (basicTotal + max).toFixed(2);
    this.setState({ addOverheads: addOverheads });

    // DEALER PRICE
    let selectedRotatingAssembly =
      this.state.selectedRotatingAssembly == "Yes" ? 5 : 0;
    let one1 = parseFloat(addOverheads * 1.2);
    let two2 = parseFloat(selectedRotatingAssembly);

    let dealerPrice = Math.round(one1 + two2);
    this.setState({ dealerPrice: dealerPrice });

    // CUSTOMER PRICE
    let c_discountRate = addOverheads * 1.45;
    c_discountRate =
      parseFloat(c_discountRate) + parseFloat(selectedRotatingAssembly);
    this.setState({ customerPrice: Math.round(c_discountRate) });
  };
  render() {
    return (
      <div>
        <div class="calculator-section">
          <h3>For Small Pipe Pen Stand</h3>
          <br />
          <div class="form-group">
            <label for="">Qty</label>
            <br />
            <input
              type="number"
              onChange={(e) => {
                if (e.target.value < 0) {
                  this.setState(
                    {
                      quantity: 6000,
                    },
                    () => this.handleCalculateByQty()
                  );
                } else {
                  this.setState(
                    {
                      quantity: e.target.value,
                    },
                    () => this.handleCalculateByQty()
                  );
                }
              }}
              id=""
              value={this.state.quantity}
              min={0}
            />
          </div>
          <div class="form-group">
            <label for="">Inner </label>
            <select
              class="form-control"
              onChange={(e) => {
                this.setState(
                  {
                    selectedInner: e.target.value,
                  },
                  () => this.handleCalculateByInner()
                );
              }}
              value={this.state.selectedInner}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Outer Media </label>
            <select
              onChange={(e) => {
                this.setState(
                  {
                    selectedOuterMedia: e.target.value,
                  },
                  () => this.handleCalculateOuterMedia()
                );
              }}
              value={this.state.selectedOuterMedia}
              class="form-control"
            >
              <option value="Paper">Paper</option>
              <option value="Film">Film</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Printing on Duplex </label>
            <select
              onChange={(e) => {
                this.setState(
                  {
                    selectedPrinting: e.target.value,
                  },
                  () => this.handleCalculatePrinting()
                );
              }}
              value={this.state.selectedPrinting}
              class="form-control"
            >
              <option value="Without Box">Without Box</option>
              <option value="White Box">White Box</option>
              <option value="4col Box">4col Box</option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Master Carton Details </label>
            <select
              class="form-control"
              onChange={(e) => {
                this.setState(
                  {
                    selectedMasterCartonDetails: e.target.value,
                  },
                  () => this.handleCalculateMasterCartoonMedia()
                );
              }}
              value={this.state.selectedMasterCartonDetails}
            >
              {this.state.mastCartoonList.map((item, index) => {
                return (
                  <option value={item.label} key={index}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div class="form-group">
            <label for="">Rotating Assembly </label>
            <select
              onChange={(e) => {
                this.setState(
                  {
                    selectedRotatingAssembly: e.target.value,
                  },
                  () => this.calculateTotal()
                );
              }}
              value={this.state.selectedRotatingAssembly}
              class="form-control"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <table>
            <tr>
              <th>Molding</th>
              <td>{this.state.molding.toFixed(2)}</td>
            </tr>
            <tr>
              <th>Inner</th>
              <td>{this.state.inner}</td>
            </tr>
            <tr>
              <th>Outer (Paper)</th>
              <td>{this.state.outerPaper}</td>
            </tr>
            <tr>
              <th>Outer (Film)</th>
              <td>{this.state.outerFilm} </td>
            </tr>
            <tr>
              <th>Duplex Box</th>
              <td>{this.state.duplexBox}</td>
            </tr>
            <tr>
              <th>Master Carton</th>
              <td>{this.state.masterCartoon}</td>
            </tr>
            <tr>
              <th>Packing Mat + Labour</th>
              <td>{this.state.pakingMat}</td>
            </tr>
            <tr>
              <th>Labour</th>
              <td>{this.state.labour}</td>
            </tr>
            <tr>
              <th>Other Material</th>
              <td>{this.state.otherMaterial}</td>
            </tr>
            <tr>
              <th>Designing Charges</th>
              <td>{this.state.designningCharges}</td>
            </tr>
            <tr>
              <th>Basic Total</th>
              <td>{this.state.basicTotal}</td>
            </tr>
            <tr>
              <th>Add Overheads</th>
              <td>{this.state.addOverheads}</td>
            </tr>
            <tr>
              <th>Rotating Assembly</th>
              <td>{this.state.rotatingAssembly}</td>
            </tr>
            <tr>
              <th>Dealer Price</th>
              <td>{this.state.dealerPrice}</td>
            </tr>
            <tr>
              <th>End Customer Price</th>
              <td>{this.state.customerPrice}</td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}
